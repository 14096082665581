import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import Img from "../../components/Img.js";
import SocialWrapper from "../../components/SocialWrapper";
import H from "../../components/Headline";
import { useAlternateLangs } from "../../components/Hreflangs.js";;
import Banner from "../../components/Banner";
import EmailSignupSimplePlugin from "../../components/EmailSignupSimplePlugin";
import { experienceYears } from "../../components/Helpers";

const StyledMiddle = styled.div`
  margin: 0vmin 1vmin;
  .container {
    margin: 7vmin 0vmin;
    display: flex;
  }

  h1 {
    padding: 0;
    margin: 0rem 0rem 1rem 0rem;
  }

  .content {
    width: 60%;
    margin-right: 1rem;
  }

  .image-wrapper {
    margin: 0rem 0rem auto 0rem;
    width: 30%;

    & .gatsby-image-wrapper {
      box-shadow: 0px 0px 3px var(--foreground);
      margin-bottom: 1rem;
    }
  }
  @media all and (max-width: 540px) {
    justify-content: center;
    .container {
      flex-direction: column-reverse;
    }

    .content {
      width: 100%;
      margin-right: none;
    }

    .image-wrapper {
      margin: 1rem auto 4rem auto;
      width: 60%;
    }
  }
`;

const StyledSecondaryContent = styled.div`
  grid-area: secondary;
  background: orange;
  display: flex;

  .card {
    border-top: 1px solid var(--foreground);
    &:first-child {
      border-right: 1px solid var(--foreground);
    }
  }

  @media all and (max-width: 700px) {
    flex-direction: column;
  }
`;

// Breadcrumb data

const breadCrumbLevels = {
  Home: "/en",
  "About me": "/en/about"
};

// Hreflang data

const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/about"
);

const About = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO title="About Matthias Kupperschmidt" lang="en" alternateLangs={alternateLangs} />
      <MainContent maxWidth="700">
        <StyledMiddle>
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} className="breadcrumb" />
          <div className="container">
            <div className="content">
              <H as="h1">Hi, I'm Matthias</H>
              <p>I optimize websites for Google’s search results and implement analytics setups.</p>
              <p>
                I’m German, living in Copenhagen, Denmark. I have worked for {experienceYears} years in multiple digital agencies
                before I started working as a <Link to="/en/google-analytics-freelancer">freelancer for Google Analytics</Link> and SEO.
              </p>
              <p>
                According to several personality tests, I am very analytical, rational and structured, while being
                goal-oriented and pragmatic at the same time.
              </p>
              <H as="h2">My story</H>
              <p>
                With 14, I built my first website to trade movies on the schoolyard. It was the best way to show my
                movie collection to my classmates. I remember creating the first html's with Microsoft Word and later
                with an editor called "Phase5".
              </p>
              <p>
                Later I built my own ecommerce webshop for table soccer (or “fooseball” as we call it), called
                "Kickerkult". I was 18 and an aspiring, wannabe-professional table soccer player, so it was a good
                combination of two interests I had.
                <br /> During that time I played many tournaments in Berlin and across Germany. Meanwhile, I managed
                my webshop, learned to send email newsletters and started doing SEO.
                <br />
                Eventually I got somewhat hooked with the web and its possibilities and wanted to focus my career on
                it.
              </p>
              <p>
                Today, I am helping companies of different sizes to get to the top of Google’s search rankings and to
                track their user behavior - no matter where in the world the company is from.
              </p>
              <p>
                Well and additionally, I write about SEO and Analytics topics on my <Link to="/en/blog">blog</Link>.
              </p>
              <H as="h2">
                Which city is the next Silicon Valley?
                <br />
                Yours!
              </H>
              <p>
                I’m a firm believer that one must not move to the big, glorious cities to accomplish a great career.
                Through the internet, modern companies, professionals and like-minded, find each other and work
                together - regardless of geographical distance.
              </p>
            </div>
            <div className="image-wrapper">
              <Img clickable="false" src="about/matthias-kupperschmidt-about-me-portrait.jpeg" alt="matthias kupperschmidt" />
              <SocialWrapper align="center" twitter="mattzipan" linkedin="matthiaskupperschmidt" github="mattzipan" />
            </div>
          </div>
        </StyledMiddle>
      </MainContent>
      <StyledSecondaryContent>
        <Banner>
          <div className="banner__header serif headline">Let's stay in touch!</div>
          <div className="serif">
            Don’t worry I will barely email you. Signup here to be sure to hear when I have something important to
            share.
          </div>
          <div className="emailwrapper">
            <EmailSignupSimplePlugin />
          </div>
        </Banner>
        <Banner>
          {/* <div className="banner__header serif headline">
                “I need help with SEO or analytics”
              </div>
              <div className="serif">
                Don’t worry I will barely email you. Signup here to be sure to
                hear when I have something important to share.
              </div> */}
        </Banner>
      </StyledSecondaryContent>
    </React.Fragment>

  </Layout>
);

export default React.memo(About);
